<template>
  <v-container
    id="user-auth"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card>
          <template #heading>
            <div class="text-h3 font-weight-light">
              Authenticating ...
            </div>
          </template>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    created () {
      const token = this.$router.currentRoute.query.jwt || undefined
      if (token !== undefined) {
        this.$store.commit('SET_AUTH', token)
        this.$router.push({ path: '/' })
      }
    },
  }
</script>

<style></style>
